import React from "react";
import Link from 'gatsby-link';
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Title, Section, Box, Text, Span } from "../../components/Core";
import { device } from "../../utils";
import { Parallax, Background } from 'react-parallax';
import imgMobile from "../../assets/image/jpeg/prphairloss.jpg";
import Recovery from "../../sections/rhino/rhinore.js";
import icon3dModel from "../../assets/image/png/icon-3d-model.png";
import iconLayout from "../../assets/image/png/icon-layot-25.png";
import SectionOne from '../../sections/countersection/countersection';
import Newarrow from '../../assets/image/jpeg/enter.svg'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';


const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;






const Arrow = styled.div`


width: 1.8em;
margin-left: 0.8em;
opacity: 0.6;

`;


const Iwrap = styled.div`

display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-bottom: 1em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;


@media screen and (max-width: 767px)
{
    margin-bottom: 8px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

@media screen and (max-width: 991px)
 {
    margin-bottom: 8px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
}


`;



const Iline = styled.div`

width: 6em;
height: 1px;
margin-right: 1em;
background-color: rgb(171, 142, 102);

@media screen and (max-width: 991px)
 {
  width: 3em;
}

`;

const Itext = styled.div`

margin-top: 0px;
margin-bottom: 0px;
color: rgb(171, 142, 102);
font-size: 14px;
font-weight: 400;


@media screen and (max-width: 991px)
 {
    font-size: 12px;
}

`;



const SecondText = styled.h3`

transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
  0deg) rotateY(
  0deg) rotateZ(
  0deg) skew(
  0deg, 
  0deg);
      transform-style: preserve-3d;

      font-size: 2.2em;
      font-smooth: always;
      margin-bottom: 0.4em;
     
      color: #1a1a1a;
 
      font-weight: 100;
      letter-spacing: 2px;
      @media screen and (max-width: 767px)
     {
          margin-bottom: 24px;
          font-size: 24px;
          text-align:center;
      }

`;


const ContentCard = ({ iconImage, title, className, children, ...rest }) => (
  <Box
    {...rest}
    className={`d-flex align-items-start justify-content-start ${className}`}
  >
    <Box mr={4}>
      <img src={iconImage} alt="" />
    </Box>
    <Box>
      <Title variant="card" fontSize="24px" mb={3}>
        {title}
      </Title>
      <Text variant="small">{children}</Text>
    </Box>
  </Box>
);

const ImgStyled = styled.img`

  box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
  border-radius: 0px;
  max-width: 100%;
  @media ${device.sm} {
    max-width: 50%;
  }
  @media ${device.md} {
    max-width: 33%;
  }
  @media ${device.lg} {
    max-width: 100%;
  }
  @media ${device.xl} {
    
  }
`;

const HairContentPage = ({setIsModalOpen}) => (
  <>
    {/* <!-- Content section 2 --> */}
    <Section py={4}>
      <Container className="pt-lg-5 pb-lg-5">

          <Row>
              <Col>
                <Iwrap>


<Itext as="h2">Hair Loss Treatment London</Itext>
</Iwrap>
      <SecondText>
      What is hair loss treatment in London?

      </SecondText>
              </Col>
          </Row>
        <Row className="align-items-center">
          <Col lg="6" className="mb-4 pl-0 pr-0 ml-0 ml-0 mb-lg-0">
            <div
              className="pl-0"
              data-aos="fade-up"
              data-aos-duration="750"
              data-aos-delay="200"
              data-aos-once="true"
            >
              <ImgStyled src={imgMobile} alt="PRP Hair Loss Tratment" className="img-fluid" />
            </div>
          </Col>
          <Col lg="6" md="9">
            <Box className=" pt-lg-0">

              <Text>

                At Dermamina, we offer advanced solutions for hair loss in London. Our specialized treatments target the
                root causes of hair loss, providing safe, effective, and long-lasting results. Whether you're
                experiencing hair thinning, pattern baldness, or simply seeking to enhance the density and health of
                your hair, we have tailored solutions to meet your needs. <br /> <br />

                We understand that Hair loss can be a distressing experience, affecting both men and women of all ages.
                Factors such as genetics, hormonal changes, stress, and lifestyle choices can contribute to the onset of
                hair loss. At Dermamina, we recognize the impact that hair loss can have on your confidence and
                well-being. That's why we're dedicated to providing personalized treatments that address the underlying
                causes of hair loss, promoting natural hair regrowth and revitalization. <br /> <br />

                <Accordion allowZeroExpanded classname="">

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        PRP for Hair Loss
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Platelet-Rich Plasma (PRP) therapy is a revolutionary non-surgical treatment for hair loss,
                        offered here at Dermamina. PRP harnesses the natural healing properties of your own blood to
                        stimulate hair follicle growth and rejuvenation. Through a simple and minimally invasive
                        procedure, PRP therapy encourages the regeneration of existing hair follicles, resulting in
                        fuller, thicker, and healthier-looking hair.
                        <br /> <br />
                        Benefits of PRP Hair Treatment:<br /> <br />

                        Safe and natural solution <br />
                        Stimulates hair follicle growth <br />
                        Fuller and thicker hair appearance <br />
                        Minimal downtime <br />
                        Long-lasting results
                        <br /> <br />
                        Procedure Details:<br /> <br />

                        Duration: 20 minutes <br />
                        Cost: £280 per session <br />
                        Back to Work: Immediately<br />
                        Pain: Minimal<br />
                        Optimal Results: 3-6 sessions<br /><br />

                        <Link to="/hair-restoration-london">Find out more...</Link>
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
                <br />

                <Accordion allowZeroExpanded classname="">

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Exosomes for Hair Loss
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        At Dermamina, we also offer Exosomes therapy for hair loss, a cutting-edge treatment that
                        harnesses the regenerative power of exosome particles. These tiny vesicles contain a potent
                        blend of growth factors and proteins, which promote hair growth and combat hair loss at a
                        cellular level. Exosomes therapy works to rejuvenate hair follicles, stimulate blood
                        circulation, and reduce inflammation, resulting in improved hair density and thickness.
                        <br /> <br />Benefits of Exosomes Therapy:

                        Cellular rejuvenation and regeneration<br />
                        Improved blood circulation<br />
                        Reduction of inflammation<br />
                        Long-lasting results<br />
                        Virtually pain-free procedure
                        <br /><br />
                        Procedure Details:
                        <br /><br />

                        Duration: 40 minutes<br />
                        Cost: £380 per session<br />
                        Back to Work: Immediately
                        Pain: None to Minimal<br />
                        Optimal Results: 5 sessions<br /><br />

                        <Link to="/exosomes-hair-loss">Find out more...</Link>
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>


              </Text> <br />
              <div onClick={() => setIsModalOpen(true)}
                   activeClassName="active">
                <Bookbutton>Book appointment</Bookbutton>
              </div>
            </Box>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col lg="12">


            <SecondText className="text-center">
              Hair Loss Prices (PRP)
      </SecondText>
      <br />
      <Text className="text-center"  color="#1a1a1a">
      <b>Consultation</b><br />
        <span className="strikey">£20</span>  £0<br /> <br /></Text>
<Text className="text-center" color="red">Special offer for a limited time only!</Text>

      <Text className="text-center"  color="#1a1a1a">
        <b>1 Session</b><br />
        <span className="strikey">£320</span>  £280*<br /> <br />
        <b>3 Sessions</b><br />
        <span className="strikey">£960</span>   £840*<br /> <br />
        <b>6 Sessions</b><br />
        <span className="strikey">£1920</span>   £1680*<br /> <br />
   

      </Text>
      <br /> 

      <Text className="text-center">
     " Package Deals available on the day
      </Text>
                  </Col>
                </Row>
      </Container>
    </Section>
  </>
);

export default HairContentPage;
