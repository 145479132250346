import React from "react";
import { rgba } from "polished";
import { Container, Row, Col } from "react-bootstrap";
import CounterSati from '../../shared/Years.js';
import CounterRev from '../../shared/Rev.js';
import CounterYears from '../../shared/Sati.js';


import Counts from '../../shared/Counter.js';
import { Title, Button, Section, Box, Text } from "../../components/Core";
import styled from "styled-components";


const Iwrap = styled.div`

display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-bottom: 1em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;


@media screen and (max-width: 767px)
{
    margin-bottom: 8px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

@media screen and (max-width: 991px)
 {
    margin-bottom: 8px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
}


`;



const Iline = styled.div`

width: 6em;
height: 1px;
margin-right: 1em;
background-color: #fff;

@media screen and (max-width: 991px)
 {
  width: 3em;
}

`;

const Itext = styled.div`

margin-top: 0px;
margin-bottom: 0px;
color: #fff;
font-size: 14px;
font-weight: 400;


@media screen and (max-width: 991px)
 {
    font-size: 12px;
}

`;



const SecondText = styled.h3`

transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
  0deg) rotateY(
  0deg) rotateZ(
  0deg) skew(
  0deg, 
  0deg);
      transform-style: preserve-3d;

      font-size: 2.2em;
      font-smooth: always;
      margin-bottom: 0.4em;
     
      color: #fff;
 
      font-weight: 100;
      letter-spacing: 2px;
      @media screen and (max-width: 767px)
     {
          margin-bottom: 24px;
          font-size: 24px;
          text-align:center;
      }

`;


const FeatureCard = ({
  color = "none",
  title,
  children,
  ...rest
}) => (
  <Box width={"100%"} bg="none" p="5px" borderRadius={10} {...rest}>
    <div className="d-flex justify-content-left align-items-center">
      <Box
        size={69}
        minWidth="100%"
        minHeight={69}
        borderRadius="50%"
        color={color}
        fontSize="28px"
        className="d-flex justify-content-left align-items-center"
       
        css={`
      
            @media screen and (max-width: 767px)
            {
          
                font-size:17px;
             }
        `}
      >
     {children}
      </Box>
      
    </div>
  </Box>
);

const Feature = () => (
  <>
    <Section py={4}  className=" pb-sx-2 pt-sx-2 pt-lg-5 pb-lg-5  pl-2 pr-2" bg="#1c1c1c">
      <Container>
        <Row className="align-items-center">
          <Col
            md="8"
            lg="6"
            className="offset-lg-1 pl-lg-5 order-lg-2"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="true"
            data-aos-delay="500"
          >
            <Box>
             <Iwrap>


<Itext as="h2">Hair Loss by the numbers</Itext>
</Iwrap>
      <SecondText>
   A Clinic You Can Trust
      </SecondText>
              
            </Box>
          </Col>
          <Col md="8" lg="5" className="order-lg-1 mt-2 mt-lg-0">
            <div>
              <Row className="justify-content-center">
                <Col
                  md="12"
                  data-aos="fade-up"
                  data-aos-duration="750"
                  data-aos-once="true"
                  data-aos-delay="50"
                >
                  <FeatureCard
                
                  >
                   <CounterSati />
                  </FeatureCard>
                </Col>
                <Col
                  md="12"
                  className="mt-lg-4"
                  data-aos="fade-up"
                  data-aos-duration="750"
                  data-aos-once="true"
                  data-aos-delay="50"
                >
                  <FeatureCard
                    
                  >
                  <Counts />
                  </FeatureCard>
                </Col>
                <Col
                  md="12"
                  className="mt-lg-4"
                  data-aos="fade-up"
                  data-aos-duration="750"
                  data-aos-once="true"
                  data-aos-delay="50"
                >
                  <FeatureCard
                   
                  >
                   <CounterYears />
                  </FeatureCard>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Feature;
