import React, { useState } from "react"
import PageWrapper from "../components/PageWrapper";
import Content from "../sections/hl/hlcontent.js";
import Prprecovery from "../sections/hair/hairglance.js";
import Hero from "../sections/hair/hairhero.js";
import Meso from "../sections/hair/hairmeso.js";
import Work from "../sections/prp/prpwork";
import Faq from "../sections/hl/hlfaq.js";
import Pro from "../sections/prp/prppro";
import Clinic from '../sections/nose/nosefind.js';  
import PRPgallery from "../sections/hl/hlgallery.js";
import DeskHero from "../sections/hair/hairbanner.js";
import Features from "../sections/hl/hlcounters.js";
import Brand from "../sections/homepage/Brands.js";
import Head from "../components/head";
import { JsonLd } from '../components/JsonLd';
import Testimonial from "../sections/reviews/prpreviews.js";
import Deskrev from "../sections/reviews/prpdesktop.js";
import Pop from "../sections/landing/popular.js";
import Saveface from "../sections/landing/saveface.js";
import Vid from "../sections/prp/prpvideo.js"
import TwoColumnLayout from '../shared/TwoColumnLayout.js';
import MyImage from "../assets/image/jpeg/hloss.jpg";
import PopupForm from "../components/PopupForm/PopupForm"

const titleText = "Hair Loss";
  const textContent = "Regain Your Confidence With Dermamina's Proven Hair Loss Treatments";

const HairLossPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
<Head title="Hair Loss" image="/images/dermamina-home.jpg"  description="Revolutionary Hair Loss Treatment Solutions. Reclaim Your Confidence With Fuller Thicker Hair" keywords="Hair Loss Treatment, Hair Loss, Hair Loss London, Liquid nose job, Liquid rhinoplasty, Surgical nose job, Nose filler, Rhinoplasty filler, Non surgical rhinoplasty, No surgery nose job, Pain free nose job"/>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'Organization',
'@id': 'https://www.dermamina.com/#organization',
url: 'https://www.dermamina.com',
name: 'Dermamina',
contactPoint: {
  '@type': 'ContactPoint',
  telephone: '020 7099 6650',
  contactType: 'Customer service',
},
address:{
  '@type': 'PostalAddress',
streetAddress: '114 New Cavendish Street',
addressRegion:'London',
postalCode:'W1W 6XT',
addressLocality:'Westminster',
},
image: {
  '@type': 'ImageObject',
  url: 'https://dermamina.com/images/dermamina-home.jpg',   
},
logo: {
'@type': 'ImageObject',
url: 'https://dermamina.com/images/dermamina-logo.svg',   
width: '200',
},
'name': 'dermamina',
'sameAs': [

'https://www.facebook.com/DermaMina-1985252831708400/',
'https://www.instagram.com/dermamina',
],
}}
</JsonLd>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'BreadcrumbList',
'itemListElement': [
  {'@type': 'ListItem', 
position:1,
name:'Dermamina Clinic',
item:'https://www.dermamina.com',
},

{'@type': 'ListItem', 
position:2,
name:'Non-Surgical Rhinoplasty',
item:'https://www.dermamina.com/non-surgical-nose-job',
},

{'@type': 'ListItem', 
position:3,
name:'Tear Trough Fillers Under Eye',
item:'https://www.dermamina.com/tear-trough-under-eye',
},

{'@type': 'ListItem', 
position:3,
name:'"Revolutionary Hair Loss Treatment Solutions',
item:'https://www.dermamina.com/hair-restoration-london',
},

]
}}
</JsonLd>

      <PageWrapper headerDark>
      <TwoColumnLayout title={titleText} text={textContent} imageData={null} image={MyImage} />
      

<Prprecovery />
<PRPgallery />
<Features />
<Content
  setIsModalOpen={setIsModalOpen}
/>
<Deskrev />
<Testimonial />


<Clinic />
  <PopupForm
    isModalOpen={isModalOpen}
    setIsModalOpen={setIsModalOpen}
  />
<Faq
  setIsModalOpen={setIsModalOpen}
/>

<Pop />
 
<Brand />
<Saveface />
      </PageWrapper>
      
    </>
  );
};
export default HairLossPage;
